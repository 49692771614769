import axios from 'axios'
import router from "@/router";

export const invoiceModule = {
    namespaced: true,

    state:() => ({
        isLoading: false,
        invoiceData: null,
        invoiceFiles: null,
    }),

    mutations: {
        setInvoiceData(state, data) {            
            for(let i = 0; i < data.items.length; i++) {
                data.items[i].is_edited = false
            }

            state.invoiceData = data
        },

        setInvoceFiles(state, data) {
            for(let i = 0; i < data.length; i++) {
                const str = data[i].file.split('/')
                data[i].name = str[str.length-1]
            }
            state.invoiceFiles = data
        }
    },

    actions: {
        getInvoiceData({ commit, state, dispatch }, order_id) {
            state.isLoading = true

            axios
            .get(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/${order_id}/`, {})
            .then((response) => {
                commit('setInvoiceData', response.data.order)
                dispatch('getInvoiceFiles', order_id)
            })
            .catch((err) => {
                console.log(err);
            })

        },

        getInvoiceFiles({ state, commit }, order_id) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/orders/${order_id}/files/`, {})
            .then((response) => {
                console.log(response);
                commit('setInvoceFiles', response.data)
                state.isLoading = false
            })
            .catch((err) => {
                console.log(err);
            })
        },

        changeOrderProductCount({}, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?item_edit=" + data.item_id +"&item_quantity=" + data.item_count + "&item_price=" + data.item_price, {})
            .then((response) => {
                console.log(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
        },

        changeOrderData({ dispatch }, data) {
            console.log(data)
            if(data.date && data.number) {
                let date = data.date.split('.')
                let format_date = date[2] + '-' + date[1] + '-' + date[0]
                
                axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?incoming_invoice_number=" + data.number + "&incoming_invoice_date=" + format_date, {})
                .then((response) => {
                    if(response.data.success) {
                        document.location.reload()
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            } else {
                alert('Не заполнены поля!')
            }

        },

        changeOrderSatus({ dispatch }, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.id +"/?status=" + data.status, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getInvoiceData', data.id)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        sendFile({ dispatch }, data) {
            if (data) {
                let formData = new FormData();
                formData.append("file", data.file);
                axios
                    .post("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/orders/" + data.id + "/files/",
                        formData,
                        {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function (response) {
                        if(response.status == 201) {
                            dispatch("getInvoiceData", response.data.order)
                        }
                    })
                    .catch(function (promise) {
                        alert(promise);
                    });
            }
        },

        deleteFile({ dispatch }, file) {
            const order = router.currentRoute.value.query.order
            if(order && file) {
                axios
                .delete("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/orders/" + order + "/files/" + file + '/')
                .then(function (response) {
                    if(response.status == 204) {
                        dispatch("getInvoiceData", order)
                    }
                })
                .catch(function (promise) {
                    alert(promise);
                });
            }
        }
    }
}