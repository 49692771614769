import { createStore } from "vuex";

import axios from "axios";
import api from "@/api";

import { loginModule } from "@/store/loginModule";
import { homeModule } from "@/store/homeModule";
import { tasksModule } from "@/store/tasksModule";
import { calendarModule } from "@/store/schedule/calendarModule";
import { dayModule } from "@/store/schedule/dayModule";
import { TimeManagementModule } from "@/store/schedule/TimeManagementModule";
import { shiftPlaningModule } from "@/store/schedule/shiftPlaningModule";
import { chartsModule } from "@/store/chartsModule";
import { profileModule } from "@/store/profileModule";
import { ordersProductsModule } from "@/store/orders/ordersProductsModule";
import { ordersListModule } from "@/store/orders/ordersListModule";
import { reportMainModule } from "@/store/reports/reportMainModule";
import { reportStudyModule } from "@/store/reports/reportStudyModule";
import { reportPreparationModule } from "@/store/reports/reportPreparationModule";
import { reportShiftsModule } from "@/store/schedule/reportShiftsModule";
import { reportPlanModule } from "@/store/sales/reportPlanModule";
import { reportEditPlanModule } from "@/store/sales/reportEditPlanModule";
import { reportDDSModule } from "@/store/finance/reportDDSModule";
import { reportFoodCostModule } from "@/store/finance/reportFoodCostModule";
import { mediaModule } from "@/store/mediaModule";
import { learningModule } from "@/store/learningModule";
import { dashboardModule } from "@/store/loyalty/dashboardModule";
import { segmentationModule } from "@/store/loyalty/segmentationModule";
import { rfmModule } from "@/store/loyalty/rfmModule";
import { marketingGuests } from "@/store/loyalty/marketingGuestsModule"
import { marketingLoyalty } from "@/store/loyalty/marketingLoyaltyModule"
import { favouriteProducts } from "@/store/loyalty/favouriteProductsModule"
import { projectsModule } from "@/store/development/projectsModule";
import { estimatesModule } from "@/store/development/estimatesModule";
import { invoiceModule } from "@/store/invoiceModule";
import { motivationModule } from "@/store/reports/motivationModule";
import { profitsModule } from "@/store/profitsModule";
import { feedbackNPSModule } from "@/store/feedback/feedbackNPSModule";
import { feedbackHashtagsModule } from "@/store/feedback/feedbackHashtagsModule";
import { feedbackAllReviewsModule } from "@/store/feedback/feedbackAllReviewsModule";
import { reportProductOrdersModule } from "@/store/reports/reportProductOrdersModule";
import { reportByProductsModule } from "@/store/reports/reportByProductsModule";
import { reportFoodWorkersModule } from "@/store/reports/reportFoodWorkersModule";
import { reportWriteOffModule } from "@/store/reports/reportWriteOffModule";
import { reportByDaysModule } from "@/store/reports/reportByDaysModule";
import { reportReservation } from "@/store/reports/reportReservationModule";
import { reportByHoursModule } from "@/store/reports/reportByHoursModule";
import { reportStopListsModule } from "@/store/reports/reportStopListsModule";
import { reportTableUpdatesModule } from "@/store/reports/reportTableUpdatesModule";
import { productsModule } from "@/store/productsModule";
import { gameModule } from "@/store/games/gameModule";
import { documentsRegistryModule } from "@/store/documentsRegistryModule";
import { reportGameProgressModule } from "@/store/reports/reportGameProgressModule";
import { reportFillingAverageCheckModule } from "@/store/reports/reportFillingAverageCheckModule";
import { reportNonIntegratedOrdersModule } from "@/store/reports/reportNonIntegratedOrdersModule";
import { reportPromocodesModule } from "@/store/loyalty/reportPromocodesModule";
import { reportOrderHelpersModule } from "@/store/reports/reportOrderHelpersModule";
import { reportGuestWaitTimeModule } from "@/store/reports/reportGuestWaitTimeModule";

export default createStore({
    modules: {
        login: loginModule,
        home: homeModule,
        tasks: tasksModule,
        calendar: calendarModule,
        day: dayModule,
        timeManagement: TimeManagementModule,
        shiftPlaning: shiftPlaningModule,
        charts: chartsModule,
        profile: profileModule,
        ordersProducts: ordersProductsModule,
        ordersList: ordersListModule,
        reportMain: reportMainModule,
        reportStudy: reportStudyModule,
        reportPreparation: reportPreparationModule,
        reportShifts: reportShiftsModule,
        reportPlan: reportPlanModule,
        reportEditPlan: reportEditPlanModule,
        reportDDS: reportDDSModule,
        reportFoodCost: reportFoodCostModule,
        media: mediaModule,
        learning: learningModule,
        dashboard: dashboardModule,
        segmentation: segmentationModule,
        rfm: rfmModule,
        projects: projectsModule,
        estimates: estimatesModule,
        invoice: invoiceModule,
        motivation: motivationModule,
        profits: profitsModule,
        feedbackNPS: feedbackNPSModule,
        feedbackHashtags: feedbackHashtagsModule,
        feedbackAllReviews: feedbackAllReviewsModule,
        reportProductOrders: reportProductOrdersModule,
        reportByProducts: reportByProductsModule,
        reportFoodWorkers: reportFoodWorkersModule,
        reportWriteOff: reportWriteOffModule,
        reportByDays: reportByDaysModule,
        reportReservation: reportReservation,
        products: productsModule,
        game: gameModule,
        marketingGuests: marketingGuests,
        marketingLoyalty: marketingLoyalty,
        favouriteProducts: favouriteProducts,
        reportByHours: reportByHoursModule,
        reportStopLists: reportStopListsModule,
        reportTableUpdates: reportTableUpdatesModule,
        documentsRegistry: documentsRegistryModule,
        reportGameProgress: reportGameProgressModule,
        reportFillingAverageCheck: reportFillingAverageCheckModule,
        reportNonIntegratedOrders: reportNonIntegratedOrdersModule,
        reportPromocodes: reportPromocodesModule,
        reportOrderHelpers: reportOrderHelpersModule,
        reportGuestWaitTime: reportGuestWaitTimeModule
    },

    state: {
        token: localStorage.getItem("token"),
        userData: null,
        username: "",
        first_name: "",
        points_of_sale: null,
        tcoins: 0,
        isLoading: false,
        mobileSidebar: false,
        user_permissions: null,

        sidebarItems: [
            {
                title: "Dashboard",
                name: "home",
                path: "/",
                visible: false,
                active: false,
                dropdown: false,
                access_required: false,
                icon: "home.svg",
                items: null,
            },
            {
                title: "Планы",
                name: "sales",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "sales.svg",
                items: [
                    {
                        title: "Внесение планов",
                        name: "edit_sales",
                        path: "/sales/edit_sales",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по выполнению плана",
                        name: "view_sales",
                        path: "/sales/view_sales",
                        visible: false,
                        access_required: true,
                    },
                ],
            },
            {
                title: "Лояльность",
                name: "loyalty",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "loyalty.svg",
                items: [
                    {
                        title: "Дашборд",
                        name: "loyalty_dashboard",
                        path: "/loyalty/dashboard",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Сегментация",
                        name: "loyalty_segmentation",
                        path: "/loyalty/segmentation",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "RFM-анализ",
                        name: "loyalty_rfm",
                        path: "/loyalty/rfm",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по гостям",
                        name: "loyalty_marketing_guests",
                        path: "/loyalty/marketing_guests",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет о лояльности",
                        name: "loyalty_marketing_loyalty",
                        path: "/loyalty/marketing_loyalty",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Любимые блюда приложения",
                        name: "loyalty_favourite_products",
                        path: "/loyalty/favourite_products",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Промокоды",
                        name: "reports_marketing_promocodes",
                        path: "/reports/marketing_promocodes",
                        visible: false,
                        access_required: true,
                    },
                ],
            },
            {
                title: "Расписание",
                name: "schedule",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "schedule.svg",
                items: [
                    {
                        title: "Календарь",
                        name: "schedule_calendar",
                        path: "/schedule/calendar",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Список смен",
                        name: "schedule_shifts",
                        path: "/schedule/shifts",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Временные возможности",
                        name: "schedule_time_management",
                        path: "/schedule/time_management",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Планирование смен",
                        name: "shift_planing",
                        path: "/schedule/shift_planing",
                        visible: false,
                        access_required: true,
                    },
                ],
            },
            {
                title: "Внутренние заказы",
                name: "orders_products",
                path: "/orders/products",
                visible: false,
                active: false,
                dropdown: false,
                access_required: false,
                icon: "orders.svg",
                items: null,
            },
            {
                title: "Финансы",
                name: "finance",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "finance.svg",
                items: [
                    {
                        title: "ДДС",
                        name: "finance_dds",
                        path: "/finance/dds",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Фудкост",
                        name: "finance_foodcost",
                        path: "/finance/foodcost",
                        visible: false,
                        access_required: true,
                    },
                ],
            },
            {
                title: "Отчеты",
                name: "reports",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "reports.svg",
                items: [
                    {
                        title: "Обучение",
                        name: "reports_study",
                        path: "/reports/study",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Собери кыстыбургер",
                        name: "reports_game_progress",
                        path: "/reports/game_progress",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Пульс компании",
                        name: "reports_charts",
                        path: "/reports/charts",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Сводный отчет",
                        name: "reports_main",
                        path: "/reports/main",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Планирование заготовок",
                        name: "reports_preparation",
                        path: "/reports/preparation",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Мотивация сотрудников",
                        name: "reports_motivation",
                        path: "/reports/motivation",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по дням",
                        name: "reports_by_days",
                        path: "/reports/by_days",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по принятию товаров",
                        name: "reports_product_orders",
                        path: "/reports/product_orders",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчёт по товарам",
                        name: "reports_by_products",
                        path: "/reports/by_products",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчёт по питанию сотрудников",
                        name: "reports_food_workers",
                        path: "/reports/food_workers",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Аналитика списаний",
                        name: "reports_write_off",
                        path: "/reports/write_off",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по бронированию",
                        name: "reports_reservation",
                        path: "/reports/reservation",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по часам",
                        name: "reports_by_hours",
                        path: "/reports/by_hours",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Стоп листы",
                        name: "reports_stop_lists",
                        path: "/reports/stop_lists",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Оборачиваемость столов",
                        name: "reports_table_updates",
                        path: "/reports/table_updates",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Наполняемость среднего чека",
                        name: "reports_filling_average_check",
                        path: "/reports/filling_average_check",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Неинтегрированные заказы",
                        name: "reports_non_integrated_orders",
                        path: "/reports/non_integrated_orders",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отчет по подсказкам для заказов",
                        name: "reports_order_helpers",
                        path: "/reports/order_helpers",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Ожидание гостя",
                        name: "reports_guest_wait_time",
                        path: "/reports/guest_wait_time",
                        visible: false,
                        access_required: true,
                    },
                ],
            },
            // {
            //     title: "Реестр документов",
            //     name: "documents_registry",
            //     path: "/documents",
            //     visible: false,
            //     active: false,
            //     dropdown: false,
            //     access_required: true,
            //     icon: "reports.svg",
            //     items: null,
            // },
            // {
            //     title: "Проекты",
            //     name: "projects",
            //     path: "/projects",
            //     visible: false,
            //     active: false,
            //     dropdown: false,
            //     access_required: false,
            //     icon: "projects.svg",
            //     items: null,
            // },
            {
                title: "Развитие",
                name: "development",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "projects.svg",
                items: [
                    {
                        title: "Проекты",
                        name: "development_projects",
                        path: "/development/projects",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Сметы",
                        name: "development_estimates",
                        path: "/development/estimates",
                        visible: false,
                        access_required: true,
                    },
                ]
            },
            {
                title: "Обучение",
                name: "learning",
                path: "/learning",
                visible: false,
                active: false,
                dropdown: false,
                access_required: false,
                icon: "learning.svg",
                items: null,
            },
            {
                title: "Начисления",
                name: "profits",
                path: "/profits",
                visible: false,
                active: false,
                dropdown: false,
                access_required: true,
                icon: "profits.svg",
                items: null,
            },
            {
                title: "Отзывы",
                name: "feedback",
                path: null,
                visible: false,
                active: false,
                dropdown: true,
                access_required: true,
                icon: "feedback.svg",
                items: [
                    {
                        title: "Показатели НПС",
                        name: "feedback_nps",
                        path: "/feedback/nps",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Хештеги",
                        name: "feedback_hashtags",
                        path: "/feedback/hashtags",
                        visible: false,
                        access_required: true,
                    },
                    {
                        title: "Отзывы",
                        name: "feedback_all_reviews",
                        path: "/feedback/all_reviews",
                        visible: false,
                        access_required: true,
                    },
                ],
            },
            // {
            //     title: "Товары",
            //     name: "products",
            //     path: "/products",
            //     visible: false,
            //     active: false,
            //     dropdown: false,
            //     access_required: false,
            //     icon: "products.svg",
            //     items: null,
            // },
        ],
    },

    mutations: {
        setPointsOfSale(state, data) {
            if (data.length > 0) {
                state.points_of_sale = data;
                for (let i = 0; i < data.length; i++) {
                    state.points_of_sale[i].label = data[i].name;
                    state.points_of_sale[i].value = String(data[i].code);
                }
            }
        },

        setProfileData(state, data) {
            state.userData = data;
            (state.username = data.username), (state.first_name = data.first_name);
            state.user_permissions = data.permissions;
            state.tcoins = data.tcode_bonuses;
        },

        setMobileSidebar(state, bool) {
            state.mobileSidebar = bool;
        },
    },

    getters: {
        getUserPermissions(state) {
            if(state.user_permissions) {
                return state.user_permissions
            }
        }
    },

    actions: {
        async getPointOfSale({ commit, state }) {
            try {
                const response = await axios.get(api + "/shops/", {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                commit("setPointsOfSale", response.data);
            } catch (err) {
                alert(err);
            }
        },

        getProfileData({ commit, dispatch }) {
            axios
                .get(api + "/profile/", {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    commit("setProfileData", response.data);
                    dispatch("sidebarInit");
                })
                .catch((error) => {
                    alert(error);
                });
        },

        sidebarInit({ state }) {
            const general = ["home", "learning"];

            for (let i = 0; i < state.sidebarItems.length; i++) {
                if (general.indexOf(state.sidebarItems[i].name) != -1) {
                    state.sidebarItems[i].visible = true;
                }

                if (state.sidebarItems[i].dropdown) {
                    for (let link = 0; link < state.sidebarItems[i].items.length; link++) {
                        if (state.user_permissions.indexOf(state.sidebarItems[i].items[link].name) != -1) {
                            state.sidebarItems[i].visible = true;
                            state.sidebarItems[i].items[link].visible = true;
                        }
                    }
                } else {
                    if (state.user_permissions.indexOf(state.sidebarItems[i].name) != -1) {
                        state.sidebarItems[i].visible = true;
                    }
                }
            }
        },

        dropdownController({ state }, item) {
            for (let i = 0; i < state.sidebarItems.length; i++) {
                if (state.sidebarItems[i].name === item) {
                    if (state.sidebarItems[i].active) {
                        state.sidebarItems[i].active = false;
                    } else {
                        state.sidebarItems[i].active = true;
                    }
                } else {
                    state.sidebarItems[i].active = false;
                }
            }
        },

        mobileSidebarController({ state, commit }) {
            if (state.mobileSidebar) {
                commit("setMobileSidebar", false);
            } else {
                commit("setMobileSidebar", true);
            }
        },
    },
});
