import axios from 'axios'
import api from '@/api'

export const chartsModule = {
    namespaced: true,

    state: () => ({
        chartsYearLoading: true,
        chartsYear: {
            labels: [],
            datasets: []
        },

        chartsMonthLoading: true,
        chartsMonth: {},
        chartsMonthTable: {},

        chartsFotLoading: true,
        chartsFot: {},
        chartsFotTable: {},

        conversion: {}
    }),

    mutations: {
        setChartsYear(state, data) {
            let plan = {
                label: 'План',
                backgroundColor: '#7992F9',
                borderColor: '#7992F9',
                data: [],
                tension: 0.3
            }

            let trend = {
                label: 'Тренд',
                backgroundColor: '#D8D8D8',
                borderColor: '#D8D8D8',
                data: [],
                tension: 0.3,
                spanGaps: true
            }

            let stonks = {
                label: 'Факт',
                backgroundColor: '#F2B20E',
                borderColor: '#F2B20E',
                data: [],
                tension: 0.3,
                spanGaps: true
            }

            for (let key in data) {
                state.chartsYear.labels.push(key)
                plan.data.push(data[key].plan)
                trend.data.push(data[key].trand)
                stonks.data.push(data[key].current)
            }

            state.chartsYear.datasets.push(plan, trend, stonks)
        },

        setChartsMonth(state, data) {
            state.chartsMonthTable = data['Общий']

            for (let i in data['Общий']) {
                state.chartsMonthTable[i] = data['Общий'][i]
                if (i != 'by_shops') {
                    state.chartsMonth[i] = {
                        labels: [],
                        datasets: [
                            {
                                label: 'План',
                                backgroundColor: '#7992F9',
                                borderColor: '#7992F9',
                                data: [],
                                tension: 0.3
                            },
                            {
                                label: 'Тренд',
                                backgroundColor: '#D8D8D8',
                                borderColor: '#D8D8D8',
                                data: [],
                                tension: 0.3,
                                spanGaps: true
                            },
                            {
                                label: 'Факт',
                                backgroundColor: '#F2B20E',
                                borderColor: '#F2B20E',
                                data: [],
                                tension: 0.3,
                                spanGaps: true
                            }
                        ]
                    }

                    for (let e in data["Общий"][i]) {
                        state.chartsMonth[i]['labels'].push(e)
                        state.chartsMonth[i].datasets[0].data.push(data["Общий"][i][e].plan)
                        state.chartsMonth[i].datasets[1].data.push(data["Общий"][i][e].trand)
                        state.chartsMonth[i].datasets[2].data.push(data["Общий"][i][e].current)

                    }
                } else {
                    for (let i in data['Общий']['by_shops']) {
                        state.chartsMonth[i] = {}
                        state.chartsMonthTable[i] = {}
                        for (let s in data['Общий']['by_shops'][i]) {
                            state.chartsMonthTable[i][s] = data['Общий']['by_shops'][i][s]
                            
                            state.chartsMonth[i][s] = {
                                labels: [],
                                datasets: [
                                    {
                                        label: 'План',
                                        backgroundColor: '#7992F9',
                                        borderColor: '#7992F9',
                                        data: [],
                                        tension: 0.3
                                    },
                                    {
                                        label: 'Тренд',
                                        backgroundColor: '#D8D8D8',
                                        borderColor: '#D8D8D8',
                                        data: [],
                                        tension: 0.3,
                                        spanGaps: true
                                    },
                                    {
                                        label: 'Факт',
                                        backgroundColor: '#F2B20E',
                                        borderColor: '#F2B20E',
                                        data: [],
                                        tension: 0.3,
                                        spanGaps: true
                                    }
                                ]
                            }

                            for (let e in data['Общий']['by_shops'][i][s]) {
                                state.chartsMonth[i][s]['labels'].push(e)
                                state.chartsMonth[i][s].datasets[0].data.push(data['Общий']['by_shops'][i][s][e].plan)
                                state.chartsMonth[i][s].datasets[1].data.push(data['Общий']['by_shops'][i][s][e].trand)
                                state.chartsMonth[i][s].datasets[2].data.push(data['Общий']['by_shops'][i][s][e].current)
        
                            }
                        }
                    }
                }
            }
            console.log(state.chartsMonthTable);
        },

        setFot(state, data) {
            state.chartsFotTable = data
            state.chartsFot['Общий'] = {
                labels: [],
                datasets: [
                    {
                        label: 'План',
                        backgroundColor: '#7992F9',
                        borderColor: '#7992F9',
                        data: [],
                        tension: 0.3
                    },
                    {
                        label: 'Тренд',
                        backgroundColor: '#D8D8D8',
                        borderColor: '#D8D8D8',
                        data: [],
                        tension: 0.3,
                        spanGaps: true
                    },
                    {
                        label: 'Факт',
                        backgroundColor: '#F2B20E',
                        borderColor: '#F2B20E',
                        data: [],
                        tension: 0.3,
                        spanGaps: true
                    }
                ]
            }

            for (let i in data["Общий"]) {
                state.chartsFot["Общий"]['labels'].push(i)
                state.chartsFot["Общий"].datasets[0].data.push(data["Общий"][i].plan)
                state.chartsFot["Общий"].datasets[1].data.push(data["Общий"][i].trand)
                state.chartsFot["Общий"].datasets[2].data.push(data["Общий"][i].current)

            }
        },

        setConversion(state, data) {
            state.conversion = data
        }
    },

    actions: {
        async getChartsYear({ commit, state }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/dashboard/charts/tcode/year/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setChartsYear', response.data.revenue)
            } catch (err) { // Если есть ошибка
                alert(err)
            } finally {
                state.chartsYearLoading = false
            }
        },

        async getChartsMonth({ commit, state }, data) {
            function getDate() {
                const month = String(data.month + 1).padStart(2, '0')
                const year = data.year

                return `${year}-${month}`;
            }
            const date = getDate()

            try {
                const response = await axios.get("https://admin.tcode.online/api/dashboard/charts/tcode/month?month=" + date, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setChartsMonth', response.data)
            } catch (err) { // Если есть ошибка
                alert(err)
            } finally {
                state.chartsMonthLoading = false
            }
        },

        async getFot({ commit, state }, data) {
            function getDate() {
                const month = String(data.month + 1).padStart(2, '0')
                const year = data.year

                return `${year}-${month}`;
            }
            const date = getDate()

            state.chartsFotLoading = true
            try {
                const response = await axios.get("https://admin.tcode.online/api/dashboard/charts/tcode/fot?month=" + date, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setFot', response.data)
            } catch (err) { // Если есть ошибка
                alert(err)
            } finally {
                state.chartsFotLoading = false
            }
        },

        async getConversion({ commit, state }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/dashboard/charts/tcode/conversion/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setConversion', response.data)
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        }
    }
}