import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import axios from "axios";
import api from "@/api";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            title: "Dashboard",
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: "Авторизация",
        },
        component: function () {
            return import("../views/LoginView.vue");
        },
    },
    {
        path: "/products",
        name: "products",
        meta: {
            title: "Товары",
        },
        component: function () {
            return import("../views/products/ProductsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/products/:shop/:id",
        name: "current_products",
        meta: {
            title: "Товар",
        },
        component: function () {
            return import("../views/products/ProductView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/charts",
        name: "reports_charts",
        meta: {
            title: "Пульс компании",
            group: "reports",
        },
        component: function () {
            return import("../views/ChartsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/by_hours",
        name: "reports_by_hours",
        meta: {
            title: "Отчет по часам",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportByHoursView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/order_helpers",
        name: "reports_order_helpers",
        meta: {
            title: "Отчет по подсказкам для заказов",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportOrderHelpersView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/schedule/calendar",
        name: "schedule_calendar",
        meta: {
            title: "Расписание - Календарь",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/CalendarView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/schedule/calendar/week_autofill",
        name: "schedule_week_autofill",
        meta: {
            title: "Расписание - Календарь",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/ShiftAutoFillWeekView.vue");
        },
    },
    {
        path: "/schedule/shifts",
        name: "schedule_shifts",
        meta: {
            title: "Расписание - Список смен",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/ReportShiftsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/schedule/day",
        name: "schedule_day",
        meta: {
            title: "Расписание - Отчет по дням",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/DayView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/schedule/time_management",
        name: "schedule_time_management",
        meta: {
            title: "Расписание - Временные возможности",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/TimeManagement.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/schedule/time_management/:id",
        name: "schedule_time_management_employee",
        meta: {
            title: "Расписание - Временные возможности",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/TimeManagementEmployee.vue");
        },
        beforeEnter: (to, from, next) => {
            guard_time_management(to, from, next);
        },
    },
    {
        path: "/schedule/shift_planing",
        name: "schedule_shift_planing",
        meta: {
            title: "Расписание - Планирование смен",
            group: "schedule",
        },
        component: function () {
            return import("../views/schedule/ShiftPlaning.vue");
        },
        beforeEnter: (to, from, next) => {
            guard_time_management(to, from, next);
        },
    },
    {
        path: "/employees",
        name: "employees",
        meta: {
            title: "Список сотрудников",
        },
        component: function () {
            return import("../views/ЕmployeesView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/orders/products",
        name: "orders_products",
        meta: {
            title: "Внутернние заказы - Товары",
            group: "orders",
        },
        component: function () {
            return import("../views/orders/OrdersProductsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/orders/order_list",
        name: "orders_list",
        meta: {
            title: "Внутернние заказы - Заказы",
            group: "orders",
        },
        component: function () {
            return import("../views/orders/OrdersListView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/tasks",
        name: "tasks",
        meta: {
            title: "Задачи",
        },
        component: function () {
            return import("../views/TasksView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/notifications",
        name: "notifications",
        meta: {
            title: "Уведомления",
        },
        component: function () {
            return import("../views/NotificationsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/study",
        name: "reports_study",
        meta: {
            title: "Обучение",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportStudyView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/game_progress",
        name: "reports_game_progress",
        meta: {
            title: "Собери кыстыбургер",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportGameProgressView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/main",
        name: "reports_main",
        meta: {
            title: "Сводный отчет",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportMainView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/preparation",
        name: "reports_preparation",
        meta: {
            title: "Планирование заготовок",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportPreparationView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/motivation",
        name: "reports_motivation",
        meta: {
            title: "Мотивация сотрудников",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/MotivationView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/product_orders",
        name: "reports_product_orders",
        meta: {
            title: "Отчет по принятию товаров",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportProductOrdersView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/by_products",
        name: "reports_by_products",
        meta: {
            title: "Отчёт по товарам",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportByProductsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/food_workers",
        name: "reports_food_workers",
        meta: {
            title: "Отчёт по товарам",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportFoodWorkersView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/write_off",
        name: "reports_write_off",
        meta: {
            title: "Аналитика списаний",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportWriteOffView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/by_days",
        name: "reports_by_days",
        meta: {
            title: "Отчет по дням",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportByDaysView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/reservation",
        name: "reports_reservation",
        meta: {
            title: "Отчет по бронированию",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportReservation.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/stop_lists",
        name: "reports_stop_lists",
        meta: {
            title: "Стоп листы",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportStopListsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/table_updates",
        name: "reports_table_updates",
        meta: {
            title: "Оборачиваемость столов",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportTableUpdatesView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/filling_average_check",
        name: "reports_filling_average_check",
        meta: {
            title: "Наполняемость среднего чека",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportFillingAverageCheckView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/non_integrated_orders",
        name: "reports_non_integrated_orders",
        meta: {
            title: "Неинтегрированные заказы",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportNonIntegratedOrdersView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/guest_wait_time",
        name: "reports_guest_wait_time",
        meta: {
            title: "Ожидание гостя",
            group: "reports",
        },
        component: function () {
            return import("../views/reports/ReportGuestWaitTimeView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/reports/marketing_promocodes",
        name: "reports_marketing_promocodes",
        meta: {
            title: "Промокды",
            group: "reports",
        },
        component: function () {
            return import("../views/loyalty/ReportPromocodes.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/sales/view_sales",
        name: "view_sales",
        meta: {
            title: "Продажи - Отчет по выполнению плана",
            group: "sales",
        },
        component: function () {
            return import("../views/sales/ReportPlanView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/sales/edit_sales",
        name: "edit_sales",
        meta: {
            title: "Продажи - Внесение планов",
            group: "sales",
        },
        component: function () {
            return import("../views/sales/ReportEditPlanView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/finance/dds",
        name: "finance_dds",
        meta: {
            title: "Финансы - ДДС",
            group: "finance",
        },
        component: function () {
            return import("../views/finance/ReportDDSView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/finance/foodcost",
        name: "finance_foodcost",
        meta: {
            title: "Финансы - Фудкост",
            group: "finance",
        },
        component: function () {
            return import("../views/finance/ReportFoodCost.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/profits",
        name: "profits",
        meta: {
            title: "Начисления",
        },
        component: function () {
            return import("../views/ProfitsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/media",
        name: "media",
        meta: {
            title: "Медиа",
            group: "media",
        },
        component: function () {
            return import("../views/media/MediaView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/media/:id",
        name: "currentMedia",
        meta: {
            title: "Медиа",
            group: "media",
        },
        component: function () {
            return import("../views/media/CurrentMediaView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/empty",
        name: "empty",
        meta: {
            title: "В разработке",
        },
        component: function () {
            return import("../views/EmptyView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/learning",
        name: "learning",
        meta: {
            title: "Обучение",
            group: "learning",
        },
        component: function () {
            return import("../views/learning/LearningView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/learning_progress",
        name: "learning_progress",
        meta: {
            title: "Прогресс тестов",
            group: "learning",
        },
        component: function () {
            return import("../views/learning/LearningProgressView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/learning/game",
        name: "game",
        meta: {
            title: "Рецепты",
            group: "game",
        },
        component: function () {
            return import("../views/learning/games/GameHomeView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/learning/game/questions",
        name: "game-questions",
        meta: {
            title: "Рецепты",
            group: "game",
        },
        component: function () {
            return import("../views/learning/games/GameQuestionsPage.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/poll/:id",
        name: "poll",
        meta: {
            title: "Тест",
            group: "learning",
        },
        component: function () {
            return import("../views/learning/PollView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/loyalty/rfm",
        name: "loyalty_rfm",
        meta: {
            title: "RFM-анализ",
            group: "loyalty",
        },
        component: function () {
            return import("../views/loyalty/RFMView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/loyalty/dashboard",
        name: "loyalty_dashboard",
        meta: {
            title: "Лояльность - Дашборд",
            group: "loyalty",
        },
        component: function () {
            return import("../views/loyalty/DashboardView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/loyalty/segmentation",
        name: "loyalty_segmentation",
        meta: {
            title: "Лояльность - Сегментация",
            group: "loyalty",
        },
        component: function () {
            return import("../views/loyalty/SegmentationView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/loyalty/marketing_guests",
        name: "loyalty_marketing_guests",
        meta: {
            title: "Лояльность - Отчет по гостям",
            group: "loyalty",
        },
        component: function () {
            return import("../views/loyalty/marketingGuestsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/loyalty/marketing_loyalty",
        name: "loyalty_marketing_loyalty",
        meta: {
            title: "Лояльность - Отчет по лояльности",
            group: "loyalty",
        },
        component: function () {
            return import("../views/loyalty/marketingLoyaltyView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/loyalty/favourite_products",
        name: "loyalty_favourite_products",
        meta: {
            title: "Лояльность - Любимые блюда приложения",
            group: "loyalty",
        },
        component: function () {
            return import("../views/loyalty/favouriteProductsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/development/projects",
        name: "development_projects",
        meta: {
            title: "Проекты",
        },
        component: function () {
            return import("../views/development/ProjectsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/development/estimates",
        name: "development_estimates",
        meta: {
            title: "Сметы",
        },
        component: function () {
            return import("../views/development/EstimatesView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/shop",
        name: "shop",
        meta: {
            title: "Магазин желаний",
        },
        component: function () {
            return import("../views/BonusShopView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/invoice",
        name: "invoice",
        meta: {
            title: "Накладная",
        },
        component: function () {
            return import("../views/InvoiceView.vue");
        },
    },
    {
        path: "/feedback/nps",
        name: "feedback_nps",
        meta: {
            title: "Отзывы - показатели НПС",
            group: "feedback",
        },
        component: function () {
            return import("../views/feedback/NPSView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/feedback/hashtags",
        name: "feedback_hashtags",
        meta: {
            title: "Отзывы - Хештеги",
            group: "feedback",
        },
        component: function () {
            return import("../views/feedback/HashtagsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/feedback/all_reviews",
        name: "feedback_all_reviews",
        meta: {
            title: "Отзывы - Отзывы",
            group: "feedback",
        },
        component: function () {
            return import("../views/feedback/AllReviewsView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/users/:id",
        name: "current_user",
        meta: {
            title: "Сотрудник",
            group: "users",
        },
        component: function () {
            return import("../views/users/CurrentUserView.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/documents",
        name: "documents_registry",
        meta: {
            title: "Реестр документов",
            group: "documents_registry",
        },
        component: function () {
            return import("../views/documents/DocumentsRegistry.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: "/documents/:id",
        name: "documents_registry_current",
        meta: {
            title: "Реестр документов",
            group: "documents_registry",
        },
        component: function () {
            return import("../views/documents/CurrentDocumentsRegistry.vue");
        },
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
];

const guard = function (to, from, next) {
    axios
        .get(api + "/profile/", {
            headers: {
                Authorization: "Token " + localStorage.getItem("token"), 
            },
        })
        .then((response) => {
            // Страницы к которым не требуются права доступа
            const general_pages = ["home", "learning", "game", "poll", "tasks", "users", "documents_registry_current"];
            const access_not_required = general_pages.indexOf(to.name) != -1;
            const page_group = general_pages.indexOf(to.meta.group) != -1;

            // Если права доступа не требуется, то переход на страницу
            if (access_not_required || page_group) {
                next();
            } else {
                // Если права требуются, то идет проверка
                const permission = response.data.permissions.indexOf(to.name) != -1;
                if (permission) {
                    next();
                }
            }
            // Проверка уровня доступа к странице
        })
        .catch((error) => {
            router.replace({ path: "/login" });
        });
};

const guard_time_management = function (to, from, next) {
    axios
        .get(api + "/profile/", {
            headers: {
                Authorization: "Token " + localStorage.getItem("token"),
            },
        })
        .then((response) => {
            const permission = response.data.permissions.indexOf("schedule_time_management_employee") != -1;
            const employees = String(response.data.employees).indexOf(to.params.id) != -1;

            if (employees || permission) {
                next();
            } else {
                from();
            }
        })
        .catch((error) => {
            router.replace({ path: "/" });
        });
};

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | TCode`;
    next();
});

export default router;
