<template>
    <header class="header">
        <navbar :class="$route.name !== 'login' ? '' : 'nav_login'" />
    </header>
    <sidebar v-if="$route.name !== 'login' && $route.name !== 'invoice'" :routeName="routeName" />
    <main
        class="main new_year" 
        :class="
            $route.name !== 'login' ? '' : 'main_login',
            $route.name === 'invoice' ? 'main_invoice' : '',
            $route.meta.group === 'game' ? 'game' : ''
            "
    >
        <router-view v-slot="{ Component }">
            <Transition name="fade">
                <component :is="Component" />
            </Transition>
        </router-view>
        <div
            v-if="$route.name !== 'login'"
            class="new_year_bottom"
        ></div>
    </main>
    <div v-if="isLoading" class="preloader-wrapper">
        <div class="preloader preloader_1"></div>
        <div class="preloader preloader_2"></div>
    </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            routeName: "home",
        };
    },

    components: {
        Navbar,
        Sidebar,
    },

    computed: {
        ...mapState({
            token: (state) => state.token,
            isLoading: (state) => state.isLoading,
        }),
    },

    methods: {
        ...mapActions({
            getProfileData: "getProfileData",
        }),
    },

    updated() {
        this.routeName = this.$route.name;
    },

    mounted() {
        if (this.token) {
            this.getProfileData();
        }
    },
};
</script>
<style scoped lang="sass">
.new_year_bottom
    position: absolute
    bottom: 0
    left: 0
    margin-top: 48px
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100px
    background: url('~@/assets/img/new_year/bg_bottom.svg')
</style>