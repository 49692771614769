<template>
    <nav class="nav">
        <div class="nav-left">
            <a href="/" class="nav__logo">
                <img src="@/assets/img/logo.svg" alt="">
                <div class="new_year">
                    <svg width="53" height="44" viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.7438 14.5294C30.2812 0.77446 10.4054 6.48869 9.16802 24.7813C8.91337 28.5321 10.0201 35.8226 10.0201 35.8226L12.0088 36.5807C12.0088 36.5807 13.4503 31.7629 14.5987 28.7842C15.3159 26.921 16.2416 25.2965 16.8368 24.3384C17.1927 23.764 17.4286 23.4324 17.4286 23.4324C18.203 25.9226 21.9127 36.0888 23.7073 34.3133L43.7438 14.5294Z" fill="#B5292A"/>
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M17.4338 23.4372C18.4188 21.9493 20.277 19.7932 21.1415 18.9893C22.0101 18.1872 18.7533 24.5273 25.9092 32.1403L23.7102 34.3121C21.9145 36.0847 18.2098 25.9404 17.4309 23.4383L17.4338 23.4372Z" fill="#181714"/>
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M18.0266 25.1866C18.2929 25.671 19.322 27.4798 20.272 28.4067C21.3727 29.4826 23.366 29.5503 23.366 29.5503C23.366 29.5503 22.4937 31.7037 21.3831 32.7176C20.1309 30.6927 18.8164 27.3962 18.0266 25.1866Z" fill="#181714"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.827 31.5151C18.8331 34.1155 23.7276 39.1692 26.3203 37.6395C32.6962 33.8782 43.3453 25.6311 47.4691 19.7682C49.6121 16.7224 45.3858 10.9267 41.9404 10.8375C38.579 10.7498 21.5476 27.0071 19.827 31.5151Z" fill="#F9F9F6"/>
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M47.8589 16.464C43.0307 22.5673 31.5494 32.1621 25.8858 34.6456C24.0714 35.4417 21.5981 33.7881 19.8429 31.4784C19.8376 31.4906 19.8323 31.5028 19.827 31.5151C18.8332 34.1155 23.7277 39.1692 26.3204 37.6395C32.6962 33.8782 43.3454 25.6311 47.4691 19.7682C48.1202 18.8428 48.183 17.6621 47.8589 16.464Z" fill="#181714"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2226 38.9561C11.1166 39.766 8.70159 38.6191 7.84932 36.4031C6.99706 34.1871 8.0212 31.7175 10.1271 30.9075C12.233 30.0976 14.6481 31.2445 15.5004 33.4606C16.3526 35.6766 15.3285 38.1462 13.2226 38.9561Z" fill="#F9F9F6"/>
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M7.68951 33.6901C7.46642 34.5493 7.50334 35.4949 7.8522 36.402C8.70674 38.6239 11.1137 39.7672 13.2254 38.955C15.0845 38.2401 16.0963 36.25 15.7317 34.2704C15.4096 35.5135 14.5421 36.5696 13.293 37.05C11.1813 37.8622 8.77433 36.7189 7.9198 34.497C7.81639 34.2281 7.74253 33.9581 7.69128 33.6861L7.68951 33.6901Z" fill="#181714"/>
                        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M21.2617 35.6269C22.1102 36.1078 23.6716 36.691 25.5653 36.2917C28.092 35.7609 39.9267 27.146 42.6964 25.1142C37.7071 29.9058 30.9088 34.9354 26.3204 37.6395C24.8739 38.4909 22.7181 37.2985 21.2617 35.6269Z" fill="#181714"/>
                    </svg>
                </div>
            </a>
            <h1
                class="nav__name"
                v-if="
                    $route.name !== 'login' && 
                    first_name !== '' && 
                    $route.name !== 'invoice'
                "
            >
                Добрый день, {{ first_name }}!
            </h1>
            <h1
                class="nav__name"
                v-else-if="
                    $route.name !== 'login' && 
                    first_name !== '' && 
                    $route.name !== 'invoice'
                "
            >
                Добрый день, {{ username }}!
            </h1>
        </div>
        <div class="nav-right">
            <!-- <input type="checkbox" class="input-lang-switch" id="switch" />
            <label class="lang-switch" for="switch">
                <span class="lang-switch__span_1">RU</span>
                <span class="lang-switch__span_2">EN</span>
            </label>
            <CustomSelect
                v-if="$route.name !== 'login'"
                v-model="selectShops"
                :options="optionShops"
            /> -->
            <!-- <button class="nav__button"  v-if="$route.name !== 'login'">
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4423 8.50028V11.1835C11.4423 11.3534 11.4856 11.5205 11.5682 11.6691L12.2516 12.8992C12.6219 13.5658 12.1399 14.3849 11.3774 14.3849H1.69951C0.937029 14.3849 0.455062 13.5658 0.825357 12.8992L1.50877 11.6691C1.5913 11.5205 1.63462 11.3534 1.63462 11.1835V8.50028V6.61566C1.63462 4.40652 3.42548 2.61566 5.63462 2.61566H6.10256" stroke="#2E343B" stroke-width="0.8" stroke-linecap="round"/>
                    <path d="M3.92334 14.3849H9.15411V15.0003C9.15411 16.1049 8.25868 17.0003 7.15411 17.0003H5.92334C4.81877 17.0003 3.92334 16.1049 3.92334 15.0003V14.3849Z" stroke="#2E343B" stroke-width="0.8"/>
                    <ellipse cx="11.1154" cy="3.26923" rx="3.26923" ry="3.26923" fill="#F2B20E"/>
                </svg>
            </button> -->
            <router-link
                v-if="$route.name !== 'login' && getUserPermissions && getUserPermissions.includes('documents_registry')"
                to="/documents"
                class="nav__button" 
            >
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0036 18.7166C12.3028 18.7166 14.6015 18.7171 16.9007 18.7166C18.1575 18.7166 19 17.871 19 16.6101C19 14.0106 19 11.4117 18.9995 8.81272C18.999 7.56484 18.1535 6.71822 16.9077 6.71722C15.8207 6.71672 14.7337 6.72173 13.6468 6.71522C12.9866 6.71171 12.4471 6.95567 12.0163 7.45413C11.4688 8.08733 10.9098 8.71052 10.3693 9.34974C10.0347 9.7455 9.62393 9.92935 9.10799 9.92784C7.09633 9.92183 5.08467 9.92384 3.07301 9.92634C2.12628 9.92734 1.38343 10.4373 1.11093 11.2864C1.04331 11.4968 1.00775 11.7263 1.00674 11.9472C0.99823 13.5277 0.99873 15.1087 1.00324 16.6892C1.00624 17.8499 1.87082 18.7136 3.03143 18.7156C5.35566 18.7186 7.67939 18.7166 10.0036 18.7166ZM9.99561 18.1165C7.67739 18.1165 5.35917 18.1175 3.04045 18.116C2.20493 18.1155 1.60433 17.5213 1.60283 16.6917C1.59982 15.1107 1.60032 13.5297 1.60483 11.9487C1.60534 11.8009 1.62637 11.6481 1.66795 11.5064C1.85028 10.8897 2.37624 10.527 3.07802 10.5265C5.07164 10.525 7.06477 10.519 9.0579 10.5295C9.78823 10.5335 10.3753 10.2795 10.8462 9.71594C11.3711 9.08824 11.9226 8.48259 12.4556 7.8614C12.7737 7.4907 13.1659 7.31236 13.6548 7.31536C14.7357 7.32187 15.8167 7.31736 16.8977 7.31736C17.8204 7.31787 18.3984 7.89196 18.3984 8.81021C18.3989 11.4102 18.3989 14.0096 18.3984 16.6096C18.3984 17.5429 17.8249 18.117 16.8927 18.117C14.5945 18.117 12.2953 18.1165 9.99561 18.1165Z" fill="black" stroke="black" stroke-width="0.24"/>
                    <path d="M3.35416 5.29738C3.44383 5.29738 3.51045 5.29738 3.57757 5.29738C4.6956 5.29738 5.81414 5.29789 6.93218 5.29738C7.23923 5.29738 7.33541 5.2022 7.33541 4.89562C7.33591 3.77749 7.33591 2.65885 7.33591 1.54072C7.33591 1.47309 7.33591 1.40596 7.33591 1.32882C10.4416 1.32882 13.5307 1.32882 16.6489 1.32882C16.6489 1.40196 16.6489 1.47409 16.6489 1.54573C16.6489 2.8953 16.6489 4.24488 16.6489 5.59395C16.6489 5.64405 16.6479 5.69414 16.6494 5.74374C16.6549 5.97768 16.7696 6.11745 16.9539 6.11645C17.1383 6.11544 17.248 5.97868 17.2485 5.73923C17.2495 4.18977 17.2495 2.64032 17.2485 1.09086C17.2485 0.817843 17.1463 0.717652 16.8748 0.717652C13.6198 0.717652 10.3649 0.716149 7.11 0.723663C6.99429 0.724164 6.85003 0.786283 6.76737 0.868439C5.47602 2.14788 4.19319 3.43583 2.90534 4.71928C2.80015 4.82398 2.75207 4.93519 2.75257 5.08348C2.75557 6.38897 2.75407 7.69496 2.75457 9.00095C2.75457 9.25944 2.85726 9.39269 3.05011 9.39369C3.24396 9.3942 3.35316 9.25844 3.35366 9.00445C3.35466 7.84875 3.35416 6.69305 3.35416 5.53734C3.35416 5.4637 3.35416 5.39056 3.35416 5.29738ZM3.87211 4.69223C4.82234 3.74242 5.78409 2.78109 6.72079 1.8448C6.72079 2.76105 6.72079 3.72388 6.72079 4.69223C5.75002 4.69223 4.79529 4.69223 3.87211 4.69223Z" fill="black" stroke="black" stroke-width="0.24"/>
                    <path d="M6.77375 13.9917C5.71883 13.9917 4.66391 13.9917 3.60849 13.9917C3.55239 13.9917 3.49478 13.9862 3.44018 13.9962C3.27889 14.0263 3.18121 14.1269 3.18171 14.2918C3.18221 14.4566 3.28039 14.5558 3.44119 14.5868C3.48977 14.5958 3.54087 14.5913 3.59046 14.5913C5.71933 14.5913 7.84821 14.5913 9.97708 14.5913C10.0272 14.5913 10.0783 14.5963 10.1264 14.5868C10.2866 14.5548 10.3828 14.4601 10.3823 14.2908C10.3818 14.1219 10.2856 14.0263 10.1249 13.9962C10.0703 13.9862 10.0126 13.9917 9.95655 13.9917C8.89662 13.9917 7.83519 13.9917 6.77375 13.9917Z" fill="black" stroke="black" stroke-width="0.24"/>
                    <path d="M6.78432 15.5204C5.73591 15.5204 4.687 15.5204 3.63859 15.5204C3.56997 15.5204 3.50034 15.5179 3.43322 15.5274C3.28294 15.5479 3.19829 15.6451 3.18276 15.7889C3.16723 15.9352 3.23836 16.0444 3.37862 16.0935C3.44173 16.1155 3.51336 16.1195 3.58099 16.1195C5.71587 16.121 7.85026 16.1205 9.98514 16.1195C10.0528 16.1195 10.1244 16.1155 10.1875 16.095C10.3303 16.0484 10.3944 15.9392 10.3824 15.7914C10.3698 15.6421 10.2842 15.5514 10.1364 15.5279C10.0693 15.5174 9.99967 15.5209 9.93105 15.5209C8.88163 15.5199 7.83323 15.5204 6.78432 15.5204Z" fill="black" stroke="black" stroke-width="0.24"/>
                </svg>
            </router-link>
            <button class="nav__button" v-if="$route.name !== 'login'" @click="userLogout()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.8623 15H2.61928C1.72511 15 1.00024 14.2751 1.00024 13.381V2.61905C1.00024 1.72487 1.72511 1 2.61928 1H4.8623" stroke="black" stroke-linecap="round"/>
                    <path d="M4.8623 7.5C4.58616 7.5 4.3623 7.72386 4.3623 8C4.3623 8.27614 4.58616 8.5 4.8623 8.5V7.5ZM15.3538 8.35355C15.5491 8.15829 15.5491 7.84171 15.3538 7.64645L12.1718 4.46447C11.9765 4.2692 11.66 4.2692 11.4647 4.46447C11.2694 4.65973 11.2694 4.97631 11.4647 5.17157L14.2931 8L11.4647 10.8284C11.2694 11.0237 11.2694 11.3403 11.4647 11.5355C11.66 11.7308 11.9765 11.7308 12.1718 11.5355L15.3538 8.35355ZM4.8623 8.5H15.0002V7.5H4.8623V8.5Z" fill="black"/>
                </svg>
            </button>
        </div>
        <div class="nav-mobile">
            <button class="nav__button" v-if="$route.name !== 'login'" @click="mobileSidebarController()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.20001 1.20001H14.8" stroke="black" stroke-linecap="round"/>
                    <path d="M1.20007 8.00006H14.8001" stroke="black" stroke-linecap="round"/>
                    <path d="M1.20007 14.8H14.8001" stroke="black" stroke-linecap="round"/>
                </svg>
            </button>
            <!-- <CustomSelect
                v-if="$route.name !== 'login'"
                v-model="selectShops"
                :options="optionShops"
            /> -->
            <!-- <button class="nav__button" v-if="$route.name !== 'login'">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.25 6.76469V9.36723C12.25 9.55025 12.3002 9.72976 12.3952 9.88621L13.0778 11.0104C13.4824 11.6768 13.0026 12.5294 12.223 12.5294H1.77703C0.997392 12.5294 0.517625 11.6768 0.92224 11.0104L1.60479 9.88621C1.69977 9.72976 1.75 9.55025 1.75 9.36723V6.76469V5C1.75 2.79086 3.54086 1 5.75 1H7.5" stroke="black" stroke-linecap="round"/>
                    <path d="M4.66666 12.5293H9.33332V12.9999C9.33332 14.1044 8.43789 14.9999 7.33332 14.9999H6.66666C5.56209 14.9999 4.66666 14.1044 4.66666 12.9999V12.5293Z" stroke="black"/>
                    <circle cx="11" cy="3" r="2" fill="#E03333"/>
                </svg>
            </button> -->
            <a href="#" class="nav__logo" v-if="$route.name === 'login'">
                <img src="@/assets/img/logo.svg" alt="">
            </a>
            <div class="nav-mobile-switch" v-if="$route.name === 'login'">
                <input type="checkbox" class="input-lang-switch" id="switch_mobile" />
                <label class="lang-switch" for="switch_mobile">
                    <span class="lang-switch__span_1">RU</span>
                    <span class="lang-switch__span_2">EN</span>
                </label>
            </div>
        </div>
    </nav>
</template>
<script>
import CustomSelect from '@/components/CustomSelect.vue'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            selectShops: 1,
            optionShops: [
                { label: 'Тюбетей', value: 1 },
                { label: 'Татар', value: 2 },
            ]
        }
    },

    computed: {
        ...mapState ({
            token: state => state.token,
            username: state => state.username,
			first_name: state => state.first_name,
        }),

        ...mapGetters({
            getUserPermissions: 'getUserPermissions'
        })
    },

    components: {
        CustomSelect
    },

    methods: {
        ...mapActions ({
            mobileSidebarController: 'mobileSidebarController',
        }),

        userLogout() {
            this.token = null
            localStorage.removeItem('token')
            this.$router.replace('/login')
        },
    },
}
</script>
<style scoped lang="sass">
.nav__logo
    & img
        position: relative
    & .new_year
        position: absolute
        top: 0
        left: 0
</style>