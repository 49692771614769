import axios from 'axios'
import api from '@/api'

export const estimatesModule = {
    namespaced: true,

    state: () => ({
        points_of_sale: null,
        loading: true,
        shop: null,
        estimates_data: null
    }),

    mutations: {
        setPointsOfSale(state, data) {
            if (data.length > 0) {
                state.points_of_sale = data
                for (let i = 0; i < data.length; i++) {
                    state.points_of_sale[i].label = data[i].name
                    state.points_of_sale[i].value = String(data[i].code)
                }
            }
        },

        setEstimatesData(state, data) {
            state.estimates_data = data
        }
    },

    actions: {
        async getPointOfSale({ commit }) {
            try {
                const response = await axios.get(api + "/shops?report=projects", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setPointsOfSale', response.data)
            } catch (err) {
                alert(err)
            }
        },

        getEstimates({ commit, state }) {
            state.loading = true
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${state.shop}/estimates`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setEstimatesData', response.data)
                state.loading = false
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        setShop({ state, dispatch }, shop) {
            state.shop = shop
            dispatch('getEstimates')
        },

        createEstimate({ state, dispatch }, data) {
            axios
            .post(`https://admin.tcode.online/api/projects/tubatay/shops/${state.shop}/estimates/`,
            data,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.status == 201) {
                    dispatch("getEstimates")
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        estimateDelete({ state, dispatch }, id) {
            axios
            .delete(`https://admin.tcode.online/api/projects/tubatay/shops/${state.shop}/estimates/${id}/`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.status == 204) {
                    dispatch('getEstimates')
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        estimatesEdit({ state, dispatch }, data) {
            axios
            .patch(`https://admin.tcode.online/api/projects/tubatay/shops/${state.shop}/estimates/${data.id}/`,
            data,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.status == 200) {
                    dispatch("getEstimates")
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}