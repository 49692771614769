import axios from 'axios'
import router from '@/router';

export const reportWriteOffModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        defaultDate: [],
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setReportFilterset(state, data) {
            state.filterSpecs['date_range'] = []
            state.filterSpecs['store'] = []
            state.filterSpecs['shop'] = []
            state.filterSpecs['account'] = []

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(filter === 'date_range') {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            } else {
                                if(data[filter].multi_choices) {
                                    if(data[filter].choices[i].checked) {
                                        state.filterSpecs[filter].push(data[filter].choices[i].value)
                                    }
                                } else {
                                    if(data[filter].choices[i].checked) {
                                        state.filterSpecs[filter] = data[filter].choices[i].value
                                    }
                                }
                                
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query
                for(let filter in data) {
                    if(data[filter].multi_choices) {
                        state.filterSpecs[filter] = []
                    } else {
                        state.filterSpecs[filter] = ''
                    }
                }

                state.filterSpecs['date_range'] = []

                for(let query in queries) {
                    if(query) {
                        if(typeof state.filterSpecs[query] !== 'undefined') {
                            if(typeof state.filterSpecs[query] !== 'string') {
                                if(typeof queries[query] !== 'string') {
                                    state.filterSpecs[query] = queries[query]
                                } else {
                                    state.filterSpecs[query].push(queries[query])
                                }
                            } else {
                                state.filterSpecs[query] = queries[query]
                            }
                        }
                    }
                }

                queries.date_from ? state.filterSpecs['date_range'].push(queries.date_from) : state.filterSpecs['date_range'].push('')
                queries.date_to ? state.filterSpecs['date_range'].push(queries.date_to) : state.filterSpecs['date_range'].push('')
            }

            state.filterset = data
            state.filtersetLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            // Проверяет, есть ли параметры которые можно передать в запросе
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/write_off/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/write_off/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }

            state.reportLoading = false
        },

        async getReportFilterset({ commit, state }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/write_off/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                commit('setReportFilterset', response.data)
                state.filtersetLoading = false
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        resetFilters({ state }) {
            for(let filter in state.filterset) {
                state.filterSpecs[filter] = []

                if(filter !== 'title') {
                    for(let i = 0; i < state.filterset[filter].choices.length; i++) {
                        if(filter === 'date_range') {
                            state.filterSpecs[filter].push(state.filterset[filter].choices[i].value)
                        } else {
                            if(state.filterset[filter].choices[i].checked) {
                                state.filterSpecs[filter] = state.filterset[filter].choices[i].value
                            }
                        }
                    }
                }
            }
        },

        async getExcelFile({ state }) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })
                const now = new Date()
                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = `${state.excelData.title}_${now.toLocaleDateString()}.xls`
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
                
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}